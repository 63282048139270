.root {
  border-radius: var(--border-radius-default);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem !important;
  line-height: 1.8rem;
  text-align: center;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
}

.root:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.primary {
  background: var(--color-secondary);
  color: var(--color-white);
}

.primary-light {
  background: var(--color-secondary-light);
  color: var(--color-secondary);
}

.tag {
  background: var(--color-gray-10);
}

.tag-active {
  background: var(--color-secondary-light);
  color: var(--color-secondary);
}

.success {
  background: var(--color-success);
  color: var(--color-white);
}

.warning {
  background: var(--color-warning);
  color: var(--color-white);
}

.link-danger {
  background: transparent !important;
  color: var(--color-error);
}

.danger {
  background: var(--color-error);
  color: white;
}

.small {
  height: 24px;
  font-size: 1.2rem;
  width: 54px;
  min-width: 54px;
}
