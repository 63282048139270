#header {
  width: 100%;
  background: var(--color-white) !important;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 60px;
  position: fixed;
  z-index: 10;

  @media screen and (min-width: 961px) {
    height: 60px;
    padding: 0 50px;
  }
}

#header .header-logo img {
  resize: contain;
  width: 100px;

  @media screen and (min-width: 961px) {
    width: 120px;
  }
}

#header .header-user {
  display: flex;
  align-items: center;
}

#header .header-user span {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-black);
}

@media screen and (max-width: 740px) {
  #header .header-user {
    display: none;
  }
}

.modal-alert-container {
  width: 350px;
  height: 300px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}

.modal-alert-container .modal-alert-description {
  font-size: 14px;
  text-align: center;
}

.modal-alert-container .modal-alert-confirmation {
  margin-top: 10px;
  font-weight: bold;
}

.modal-alert-container .modal-alert-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.modal-alert-container .modal-alert-buttons .modal-alert-btn {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin: 0 5px;
  font-weight: bold;
}

.b-yes {
  background: #ff4242;
  color: #fff;
}

.b-no {
  background: #ffe2b6;
  color: #000;
}
