select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: 0;
}

select::-ms-expand {
  /* for IE 11 */
  display: none;
}

#select-container {
  min-width: 90px;
  height: 50px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-gray-20);
}

#select-container .select {
  border: none;
  background: transparent url('../../assets/icons/chevron-down.svg') 95.5% 50%
    no-repeat;

  font-size: 13px;

}