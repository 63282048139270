#menu-mobile {
  display: none;
}

@media screen and (max-width: 740px) {
  #menu-mobile {
    display: block;
  }

  #menu-mobile .menu-mobile-icon {
    font-size: 35px;
    color: var(--color-secondary);
    cursor: pointer;
  }

  .list {
    background: var(--color-white);
    width: 300px;
    height: 100vh;
  }

  .list-item-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-gray-50);
  }

  .list-item-text:hover {
    color: var(--color-secondary);
    transition: all 0.5s;
  }

  .user-content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .user-content .user-avatar {
    width: 70px;
    height: 70px;
  }

  .user-content span {
    font-size: 1.6rem;
    font-weight: 2rem;
    color: var(--color-secondary);
    margin-left: 20px;
  }
}
