.search-container {
  width: 100%;
  max-width: 320px;
  height: 48px;
  background: var(--color-white);
  border-radius: var(--border-radius-medium);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.search-container input {
  font-size: 1.6rem;
  height: 30px;
  width: 100%;
  padding-right: 20px;
  border: none;
  color: var(--color-gray-80);
}
