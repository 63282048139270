.tag-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tag-button {
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 16px;
}
