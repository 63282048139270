#menu {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  justify-content: space-between;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #f1f1f1;
}

#menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
}

#menu ul li {
  display: inline;
  padding: 0 20px;
  height: 100%;
}

#menu ul li a {
  text-decoration: none;
  font-size: 1.4rem;
  color: var(--color-gray-50);
  cursor: pointer;
}

#menu ul li a:hover {
  color: var(--color-secondary);
  transition: all 0.5s;
}

#menu .active {
  color: var(--color-secondary);
  font-weight: bold;
  transition: all 1s;
}

#menu div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#menu select {
  width: 80px;
  height: 30px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  text-align: center;
  cursor: pointer;
  appearance: none;
}

@media screen and (max-width: 740px) {
  #menu {
    display: none;
  }
}

.menu-divider {
  width: 1px;
  height: 15px;
  background: #f1f1f1;
}
