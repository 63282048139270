.modal-container {
  width: 500px;
  padding: 50px 0;
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-container-cancel {
  width: 95%;
  max-width: 500px;
  height: auto;
  padding: 30px 0;
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-container h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #2c2b43;
}

.modal-container p {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #525166;
}

.modal-input {
  width: 300px;
  padding: 5px 10px;
  border: 1px solid #ffd2de;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.modal-input input[type='radio']:checked {
  border-color: #ffd2de;
  background-color: #ffd2de;
}

.input-text {
  width: 300px;
  margin-top: 20px;
  height: 34px;
  padding: 5px 10px;
  border: 1px solid #d9dce6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.buttons-modal {
  display: flex;
  width: 450px;
  justify-content: space-between;
  margin-top: 20px;
}

.inputs-times {
  display: flex;
  width: 450px;
  justify-content: space-between;
}

.modal-p {
  width: 100%;
  padding: 0 30px;
  text-align: center;
  margin-top: 30px;
}

.modal-select {
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: flex-start;
  margin: 30px 0 20px;
  flex-direction: column;
}
.modal-select p {
  text-align: left;
}

.modal-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
}

@media screen and (max-width: 500px) {
  .buttons-modal {
    display: flex;
    width: 90%;
    gap: 30px;
  }
}
