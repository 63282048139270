.card {
  width: 100%;
  padding: 30px 20px;
  background: var(--color-white);
  border-radius: 8px !important;
  border: none !important;

  .video-fixed {
    width: 100%;
    max-width: 298px;
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 99;

    @media screen and (max-width: 600px) {
      position: relative;
      max-width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.full {
  height: 100%;
}

.vertical {
  margin: 20px 0;
}

.horizontal {
  margin: 0 20px;
}
