@font-face {
  font-family: Muli;
  src: url('../assets/fonts/Muli.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Muli;
  src: url('../assets/fonts/Muli-SemiBold.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Muli;
  src: url('../assets/fonts/Muli-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

:root {
  /* Default colors */
  --color-white: #ffffff;
  --color-background: #f0f2f8;
  --color-primary: #ffe2b6;
  --color-secondary: #ff3f72;
  --color-secondary-light: #ffe4eb;
  --color-dark: #2c2b43;
  --color-black: #000000;
  --color-gray-10: #f0f2f8;
  --color-gray-20: #d9dce6;
  --color-gray-30: #c5c9d5;
  --color-gray-50: #787887;
  --color-gray-80: #525166;
  --color-blue-light: #e3eefd;
  --color-orange-light: #fbece6;
  --color-gradient-default: #221c35, #000;

  /* Suport colors */
  --color-success: #1fba5d;
  --color-error: #ff4242;
  --color-warning: #ff9b00;

  /* Sizes e Radius */
  --border-radius-default: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 12px;

  font-size: 60%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
}

body {
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

#root {
  background: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input,
button,
textarea,
fieldset,
legend,
label,
p,
span {
  font-size: 1.4rem !important;
  outline: none;
}

thead > tr > th {
  font-size: 1.6rem !important;
  font-weight: 500;
  line-height: 2rem;
  color: var(--color-black);
}

tbody > tr > th,
td {
  font-size: 1.4rem !important;
  font-weight: normal;
  line-height: 1.6rem;
  color: var(--color-gray-80) !important;
}

hr {
  border: 1px solid var(--color-gray-30);
}

li {
  font-size: 1.4rem !important;
}

div,
section,
article,
img {
  outline: none;
}

/* Containers */
.container--sb {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  background: var(--color-background);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  // @media (min-width: 750px) {
  //   height: 100vh;
  // }
}

.content {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
}

.grid-content {
  padding: 2px 6px;
}

/* Input */
.input-default {
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--color-gray-80);
  margin: 6px 0 !important;
}

.form-control {
  min-width: 148px;
}

.transparent-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Margins and Paddings*/
.pr-1 {
  margin-right: 10px;
}

.overlay {
  width: 9999px;
  height: 9999px;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;
}

.modal-wrapper {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background: rgba(34, 34, 34, 0.2);
  backdrop-filter: blur(20px);
  color: #000;
}

.info-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Calendar
#calendar-view {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: none !important;
  overflow-y: hidden;

  @media screen and (max-width: 576px) {
    overflow-x: scroll;
  }
}

.view-agenda {
  border: 1px solid #000;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  width: 100% !important;
  min-width: 100%;
}

.fc-state-highlight {
  opacity: 0;
  border: none;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-scroller-harness {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-gray-10);
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: 0.65;
  left: 5% !important;
  right: 5% !important;
}

/* Bolds the name of the event and inherits the font size */
.fc-event {
  font-size: inherit !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

/* Remove the header border from Schedule */
.fc td,
.fc th {
  border-style: none !important;
  border-width: 1px !important;
  padding: 0 !important;
  vertical-align: top !important;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: 0.25 !important;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: normal !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

/* Calendar Header */
.fc-header-toolbar {
  margin-top: 2rem;
}

/* Material design button */
.fc-button {
  display: inline-block;
  position: relative;
  cursor: pointer;

  padding: 0.6em 1.2em !important;

  @media screen and (min-width: 961px) {
    min-height: 36px;
    min-width: 88px;
    padding: 0 6px;
  }

  line-height: 36px;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  margin: 6px 8px;
  letter-spacing: 0.01em;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.fc-button-primary {
  background-color: var(--color-secondary) !important;
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.fc-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.fc-button:focus,
.fc-button:hover {
  text-decoration: none;
}

/* The active button box is ugly so the active button will have the same appearance of the hover */
.fc-state-active {
  background-color: rgba(158, 158, 158, 0.2);
}

/* Not raised button */
.fc-state-default {
  box-shadow: None;
}

.fc-col-header-cell-cushion {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black);
}

.fc-timegrid-slots tr {
  height: 42px;
  border-bottom: 1px solid var(--color-gray-10);
  display: flex;
  align-items: center;
}

.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  color: var(--color-gray-50);
}

.fc .fc-bg-event {
  background: var(--color-secondary-light) !important;
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
}

.fc .fc-bg-event .fc-event-title {
  color: var(--color-secondary) !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  font-style: normal !important;
}
