.title-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;

  span {
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    color: var(--color-dark) !important;
    padding-right: 20px;
  }

  &__border {
    display: flex;
    flex: 1;
    height: 1px;
    background: var(--color-gray-20);
  }
}
