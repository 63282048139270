#input-container {
  position: relative;
  width: 100%;
  height: 48px;
  border: 1px solid var(--color-gray-20);
  border-radius: var(--border-radius-medium);
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 7px 0;
}

#input-container .label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
}

#input-container input.input {
  border: none;
  font-size: 1.4rem;
  padding: 2px 0;
  margin-top: 8px;
  color: var(--color-black);
}

#input-container input.input-no-padd {
  border: none;
  font-size: 1.4rem;
  padding: 2px 0;
  color: var(--color-black);
}

#input-container input.input::placeholder {
  color: transparent;
}

#input-container input:disabled {
  background: transparent;
  cursor: no-drop;
}

#input-container input.input:placeholder-shown ~ .label {
  font-size: 1.2rem;
  cursor: text;
  top: 12px;
  color: var(--color-gray-50);
}

#input-container input.input:focus ~ .label {
  font-size: 1.2rem !important;
  line-height: 1.5rem;
  color: var(--color-gray-50);
  position: absolute;
  top: 4px;
  transition: 0.2s;
}
