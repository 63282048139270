.textarea {
  textarea {
    width: 100%;
    min-height: 70px;
    height: auto;
    margin: 10px 0;
    border: 1px solid var(--color-gray-20);
    border-radius: var(--border-radius-medium);
    padding: 16px;
    font-size: 1.4rem;
    font-weight: normal;
    resize: none;
  }
}
